<template>
  <!-- 会员管理 -->
  <div>
    <div>
      <!-- 搜索区域 -->
      <div class="form">
        <!-- 手机号查询 -->
        <div>
          <span>按时间查询:</span>
          <div style="display: flex">
            <el-date-picker
              v-model="form.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div>
          <span>按手机号码查询:</span>
          <el-input placeholder="请输入电话" v-model="form.phone" clearable style="width: 200px; display: block"> </el-input>
        </div>

        <div>
          <span>按用户ID查询:</span>
          <el-input placeholder="请输入用户ID" v-model="form.userId" clearable style="width: 200px; display: block"> </el-input>
        </div>
        <div>
          <span>按钱包地址查询:</span>
          <el-input placeholder="请输入钱包地址" v-model="form.wallet" clearable style="width: 200px; display: block"> </el-input>
        </div>
        <div>
          <span>按用户状态查询:</span>
          <el-select v-model="form.status" placeholder="请选择" clearable style="width: 200px; display: block">
            <el-option
                v-for="item in userStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>

        <!-- <div class="">
		  <span>按认证等级查询</span>
		  <el-select v-model="form.approve" placeholder="请选择">
		    <el-option
		      v-for="item in attestation"
		      :key="item.value"
		      :label="item.label"
		      :value="item.value"
		    >
		    </el-option>
		  </el-select>
		</div> -->
        <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 40px" @click="doFilter">搜索</el-button>
        <download-excel
          v-if="derivedForm"
          class="export"
          style="height: 40px; margin-top: 40px; margin-left: 20px"
          :data="derivedForm"
          :fields="jsonFields"
          type="xls"
          name="用户表单.xls"
          header="用户列表"
        >
          导出
        </download-excel>
        <el-button type="primary" icon="el-icon-refresh" style="height: 40px; margin-top: 40px" @click="onRest">重置</el-button>
        <div> 
          <el-button type="primary" icon="el-icon-refresh" style="height: 40px; margin-top: 40px" @click="onAllRock">锁仓</el-button>
          <el-button type="primary" icon="el-icon-refresh" style="height: 40px; margin-top: 40px" @click="onAllRockFalse">清仓</el-button>
        </div>
        
      </div>
      <!-- 表单区域 -->
      <div style="margin-top: 30px">
        <el-table :data="dataUser" border style="width: 100%">
          <el-table-column prop="id" label="会员Id" width="100" align="center"> </el-table-column>
          <el-table-column label="姓名" prop="trueName" align="center" min-width="80"></el-table-column>
          <el-table-column prop="parentId" label="推荐人UID" width="100" align="center"> </el-table-column>
          <el-table-column prop="tel" label="手机号" width="150" align="center"> </el-table-column>
          <el-table-column prop="walletArea" label="钱包地址" width="" align="center"> </el-table-column>
          <el-table-column prop="closeAmount2" label="锁仓金额" width="" align="center"> </el-table-column>
          <!-- <el-table-column prop="inviteTel" label="邀请人(手机)" width="" align="center">
            <template slot-scope="scope">
              {{ scope.row.inviteTel || '暂无' }}
            </template>
          </el-table-column> -->
          <el-table-column prop="createTime" label="注册时间" width="" align="center"> </el-table-column>
          <el-table-column label="认证等级" min-width="120" align="center">
            <template slot-scope="scope">
              <el-button
                @click="toView(scope.row)"
                :type="scope.row.authen == 1 ? 'success' : scope.row.authen == 2 ? 'primary' : 'info'"
                size="mini"
              >
                {{ scope.row.authen == 1 ? '初级认证' : scope.row.authen == 2 ? '高级认证' : '未认证' }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="数字通证" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="through(scope.row.id)"> 查看</el-button>
            </template>
          </el-table-column>
          <!--   <el-table-column
            label="CNY"
            prop="balance"
            width="100"
          ></el-table-column> -->
          <el-table-column label="推荐人数" width="100" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="danger">{{ scope.row.inviteNum ? scope.row.inviteNum : 0 }}</el-button>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">正常</span>
              <span v-if="scope.row.status == 1">系统冻结</span>
              <span v-if="scope.row.status == 2">密码错误上限冻结</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" align="center" min-width="150">
            <template slot-scope="scope">
              <el-button
                size="mini"
                :type="!scope.row.status ? 'danger' : 'success'"
                @click="onStatus(scope.row.id, scope.row.status, scope.$index)">
                {{ !scope.row.status ? '锁定' : '解锁' }}
              </el-button>
              <el-button
                  size="mini"
                  type="danger"
                  @click="onRock(scope.row.id)">
                锁仓
              </el-button>
              <el-button
                  size="mini"
                  type="success"
                  @click="onRockFalse(scope.row.id)">
                解仓
              </el-button>
              <!-- <el-button
                size="mini"
                type="success"
                @click="onStatusPwd(scope.row.id)"
                >密码解锁</el-button
              > -->
              <el-button size="mini" type="primary" style="margin-top:20px" @click.stop="handleDelete(scope.$index, scope.row.id)">查看</el-button>
              <!-- <el-button size="mini" type="primary" style="margin-top:20px" @click.stop="handleDelete2(scope.row.id)">查看GEN信息</el-button>
              <el-button
                  size="mini"
                  type="danger"
                  style="margin-top:20px"
                  @click="updateParentId(scope.row.id)">
                修改推荐人ID
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页选项 -->
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20, 30]"
          :page-size="pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog title="YHSZ信息" :visible.sync="showTag2" width="50%">
        <div>
            <el-select v-model="tzValue" placeholder="请选择通证">
              <el-option
                v-for="item in tongOptions"
                :key="item.id"
                :label="item.tokenName"
                :value="item.id">
              </el-option>
            </el-select>
            <div>
              <p>原价钰芸数证:{{GenInfo.old.totalAmount}}</p>
              <p>可用余额:{{GenInfo.old.amount}}</p>
              <p>冻结余额:{{GenInfo.old.closeAmount}}</p>
              <p v-for="(item,i) in GenInfo.old.list" :key='i'>已锁仓{{item.day}}数:{{item.num}}个</p>
              
              <p style="margin-top:40px">特价钰芸数证:{{GenInfo.agreement.totalAmount}}</p>
              <p>可用余额:{{GenInfo.agreement.amount}}</p>
              <p>冻结余额:{{GenInfo.agreement.closeAmount}}</p>
               <p v-for="(item,i) in GenInfo.agreement.list" :key='i'>已锁仓{{item.day}}数:{{item.num}}个</p>

            </div>
        </div>
      </el-dialog>
      <!-- 弹出框 -->
      <el-dialog title="会员信息" :visible.sync="showTag" width="50%">
        <div class="tanchukuang" v-if="userInfo">
          <div>
            <div>
              <span>会员ID：</span>
              <span>{{ userInfo.id }}</span>
            </div>
            <div>
              <span>手机号码：</span>
              <span>{{ userInfo.tel }}</span>
            </div>
          </div>
          <div>
            <div>
              <span>邀请人：</span>
              <span>{{ userInfo.inviteTel }}</span>
            </div>
            <div>
              <span>注册时间：</span>
              <span>{{ userInfo.createTime }}</span>
            </div>
          </div>
          <div>
            <div>
              <span>会员等级：</span>
              <span>{{ userInfo.authen == 1 ? '初级认证' : userInfo.authen ? '高级认证' : '未认证' }}</span>
            </div>
            <div>
              <span>姓名：</span>
              <span>{{ userInfo.trueName }}</span>
            </div>
          </div>
          <div>
            <div>
              <span>身份证号码：</span>
              <span>{{ userInfo.idCard }}</span>
            </div>
            <!-- <div>
              <span>微信账号：</span>
              <span>{{userInfo.}}</span>
            </div> -->
          </div>
          <!-- <div>
            <div>
              <span>银行卡：</span>
              <span>1231321</span>
            </div>
            <div>
              <span>支付宝账号：</span>
              <span>1231321</span>
            </div>
          </div>
          <div>
            <div>
              <span>预留手机号：</span>
              <span>1231321</span>
            </div>
          </div> -->
        </div>
      </el-dialog>
      <!-- 查看通证弹出框 -->
      <el-dialog title="通证信息" :visible.sync="seeValue" width="50%">
        <el-table :data="passCard.data" style="padding: 20px">
          <el-table-column label="通证名字" prop="tokenName">
            <template slot-scope="scope">
              {{ scope.row.certificateId == 0 ? 'CNY' : scope.row.tokenName }}
            </template>
          </el-table-column>
          <el-table-column label="通证数量" prop="num"></el-table-column>
          <el-table-column label="冻结数量" prop="closeNum">
            <template slot-scope="scope">
              {{ scope.row.certificateId == 0 ? scope.row.closeAmount : scope.row.closeNum }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="ThandleSizeChange"
          @current-change="ThandleCurrentChange"
          :current-page="passCard.currentPage"
          :page-sizes="[10, 15, 20, 30]"
          :page-size="passCard.pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="passCard.total"
          style="margin: 20px 0 20px 50px"
        >
        </el-pagination>
      </el-dialog>
      <!-- 认证信息弹窗 -->
      <el-dialog title="会员认证信息" :visible.sync="userPopup">
        <el-form v-if="certification" :model="certification" label-width="100px" class="users">
          <el-form-item label="会员ID">
            <span>{{ certification.userId }}</span>
          </el-form-item>
          <el-form-item label="姓名">
            <span>{{ certification.name }}</span>
          </el-form-item>
          <el-form-item label="身份证号">
            <span>{{ certification.cardNum }}</span>
          </el-form-item>
          <el-form-item label="电话">
            <span>{{ certification.tel }}</span>
          </el-form-item>

          <el-form-item label="认证状态">
            <el-tag size="mini" effect="dark" :type="certification.grade == 1 ? 'success' : 'primary'">{{
              certification.grade == 1 ? '初级认证' : '高级认证'
            }}</el-tag>
          </el-form-item>
          <el-form-item label="审核状态">
            <el-tag
              size="mini"
              effect="dark"
              :type="certification.status == 1 ? 'success' : certification.status == 2 ? 'danger' : 'warning'"
              >{{ certification.status == 1 ? '审核通过' : certification.status == 2 ? '审核失败' : '未审核' }}</el-tag
            >
          </el-form-item>
          <el-form-item label="手持身份证">
            <el-image :src="certification.cardImage" fit="scale-down" style="width: 200px; height: 200px"></el-image>
          </el-form-item>
          <el-form-item label="身份证正面">
            <el-image :src="certification.cardFront" fit="scale-down" style="width: 200px; height: 200px"></el-image>
          </el-form-item>
          <el-form-item label="身份证反面">
            <el-image :src="certification.cardAfter" fit="scale-down" style="width: 200px; height: 200px"></el-image>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        time: '', // 时间
        phone: '', // 电话
        trueName: '', // 姓名
        approve: '',
        userId: '',
        wallet: '',
        status: null
      },
      dataUser: null,
      total: null,
      currentPage: 1,
      pageNumber: null,
      showTag: false,
      showTag2:false,
      seeValue: false,
      userPopup: false, // 用户认证信息弹窗
      tzValue:'',
      // 数字通证
      passCard: {
        total: null,
        data: null,
        currentPage: 1,
        pageNumber: 10,
        userId: null
      },
      // 用户信息
      userInfo: null,
      userId:'',
      derivedForm: '', //导出的表格数据
      // 导出表单的格式
      jsonFields: {
        ID: 'id',
        姓名: 'trueName',
        电话: 'tel',
        身份证号码: 'idCard',
        推荐人数: 'inviteNum',
        累计邀请有效用户数: 'inviteUser',
        邀请人电话: 'inviteTel',
        可提现余额: 'balance',
        冻结金额: 'closeAmount',
        注册时间: 'createTime',
        认证: 'authen'
      },
      certification: {}, // 用户认证信息
      // 认证等级
      attestation: [
        {
          value: 2,
          label: '高级'
        },
        {
          value: 1,
          label: '初级'
        },
        {
          value: 0,
          label: '未认证'
        }
      ],
      // 冻结状态
      userStatus: [
        {
          value: null,
          label: '全部'
        },
        {
          value: 0,
          label: '正常'
        },
        {
          value: 1,
          label: '系统冻结'
        },
        {
          value: 2,
          label: '密码错误冻结'
        }
      ],
      tongOptions:[],
      GenInfo:{old:{list:[]},agreement:{list:[]}}
    }
  },
  created() {
    this.onMenber()
    this.getTongz()
    // 获取所有的数据
    // this.axios.get("/admin/user/userExcel", {}).then((res) => {
    //   this.derivedForm = res.data.data;
    //   this.derivedForm.forEach((item) => {
    //     item.authen =
    //       item.authen == 1
    //         ? "初级认证"
    //         : item.authen == 2
    //         ? "高级认证"
    //         : "未认证";
    //   });
    // });
  },
  methods: {
    getTongz(){
      // this.axios.get('/admin/gen/findAll')
      // .then(res=>{
      //   this.tongOptions=res.data.data.records
      //   this.tzValue=this.tongOptions[0].id
      // })
    },
    // 搜索按钮
    doFilter() {
      this.axios
        .get('/admin/user/findAll', {
          startTime: this.form.time[0],
          endTime: this.form.time[1],
          tel: this.form.phone,
          userId: this.form.userId,
          wallet: this.form.wallet,
          status: this.form.status
        })
        .then(res => {
          console.log(res)
          this.dataUser = res.data.data.records
          this.total = res.data.data.total
          this.pageNumber = res.data.data.size
          this.currentPage = res.data.data.current
        })
    },
    // 解锁status
    onStatus(id, status, index) {
      if (status) {
        this.axios
          .get('/admin/user/updateStatus', {
            userId: id,
            status: 0
          })
          .then(res => {
            console.log(res)
            this.dataUser[index].status = 0
            this.$message({
              message: '解锁成功!',
              type: 'success'
            })
          })
      } else {
        this.axios
          .get('/admin/user/updateStatus', {
            userId: id,
            status: 1
          })
          .then(res => {
            console.log(res)
            this.dataUser[index].status = 1
            this.$message({
              message: '锁定成功!',
              type: 'success'
            })
          })
      }
    },
    //修改推荐人ID
    updateParentId(id){
        this.$prompt('请输入UID', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
           this.axios.get('/admin/user/updateParentId', {
            parentId: value,
            id
          }).then(res=>{
                this.doFilter()
                this.$message.success('修改成功')
            })
        }).catch(() => {
              
        });
    },
    // 锁仓rock
    onRockFalse(id){
      this.axios
          .get('/admin/user/rockFalseUser', {
            userId: id
          })
          .then(res => {
            console.log(res)
            this.$message({
              message: res.data.data,
              type: 'success'
            })
          })
    },
    // 解锁rock
    onRock(id) {
        this.$prompt('请输入锁仓比例,最大为1（100%）', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          this.axios
              .get('/admin/user/rockUser', {
                userId: id,
                rate: value
              })
              .then(res => {
                console.log(res)
                this.$message({
                  message: '锁定成功!',
                  type: 'success'
                })
              })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
    },
    // 解锁rock
    onAllRockFalse(){
       this.$confirm('确定清仓吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.axios
          .get('/admin/user/rockFalse', {
          })
          .then(res => {
            console.log(res)
            this.$message({
              message: res.data.data,
              type: 'success'
            })
          })
        }).catch(()=>{

        })
      
    },
    // 锁仓rock
    onAllRock() {
      this.$confirm('此操作将全部用户锁仓, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.get('/admin/user/rock', {})
            .then(res => {
              console.log(res)
              this.$message({
                message: '锁定成功!',
                type: 'success'
              })
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 密码解锁
    // onStatusPwd(id) {
    //   this.axios
    //     .get("/admin/user/updateStatus", {
    //       status: 0,
    //       userId: id,
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       this.$message({
    //         message: "解锁密码成功!",
    //         type: "success",
    //       });
    //     })
    //     .catch((err) => {});
    // },

    // 会员管理接口
    onMenber() {
      this.axios.get('/admin/user/findAll', {}).then(res => {
        this.dataUser = res.data.data.records
        this.total = res.data.data.total
        this.pageNumber = res.data.data.size
        this.currentPage = res.data.data.current
      })
    },

    // 重置input框数据
    onRest() {
      this.form = {
        time: [],
        phone: '',
        userid: '',
        wallet: ''
      }
      this.onMenber()
    },

    // 查看
    handleDelete(index, id) {
      this.showTag = true
      this.axios.get('/admin/user/findById', { id }).then(res => {
        if (res.data.code == 200) {
          this.userInfo = res.data.data
          console.log(this.userInfo)
        }
      })
    },
    //查看GEN
  handleDelete2(id){
    this.userId=id
    this.getFindUserGen()
    this.showTag2 = true
    
  },
  getFindUserGen(){
    this.axios.get('/admin/user/findUserGen',{genId:this.tzValue,userId:this.userId})
    .then(res=>{
      this.GenInfo=res.data.data
    })
  },
    /**
     * 查看认证信息
     * @param {*} item
     */
    toView(item) {
      // console.log(item)
      if (item.authen === 0) {
        this.$message.warning('该用户未认证')
      } else {
        // 查看认证信息
        this.axios.get('/admin/user/findByUserId', { userId: item.id }).then(res => {
          this.userPopup = true
          this.certification = res.data.data
          console.log(this.certification)
        })
      }
    },

    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageNumber = val
      this.axios
        .get('/admin/user/findAll', {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          // console.log(res.data.data);
          this.dataUser = res.data.data.records
        })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.axios
        .get('/admin/user/findAll', {
          currentPage: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          // console.log(res.data.data);
          this.dataUser = res.data.data.records
        })
    },
    // 查看数字通证
    through(userId) {
      this.passCard.userId = userId
      // /app/userCertificate/adminShow
      this.axios
        .get('/admin/user/getUserBalance', { id: this.passCard.userId })
        .then(res => {
          if (res.data.code == 200) {
            this.seeValue = true
            this.passCard.data = res.data.data.records
            this.passCard.total = res.data.data.total
            this.passCard.currentPage = res.data.data.current
            this.passCard.pageNumber = res.data.data.size
          }
        })
        .catch(err => {})
    },

    // 弹窗分页
    ThandleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.passCard.pageNumber = val
      this.axios
        .get('/app/userCertificate/adminShow', {
          pageNumber: this.passCard.currentPage,
          pageSize: this.passCard.pageNumber,
          userId: this.passCard.userId
        })
        .then(res => {
          // console.log(res.data.data);
          this.passCard.data = res.data.data.records
        })
    },
    ThandleCurrentChange(val) {
      this.passCard.currentPage = val
      this.axios
        .get('/app/userCertificate/adminShow', {
          currentPage: this.passCard.currentPage,
          pageSize: this.passCard.pageNumber,
          userId: this.passCard.userId
        })
        .then(res => {
          this.passCard.data = res.data.data.records
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  color: #fff;
  padding: 5px 10px;
  background-color: #409eff;
  border-radius: 10px;
}
.index .wrapper .memberInfo .cont h4[data-v-192e00bb] {
  text-rendering: optimizeLegibility;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  width: 110px;
  line-height: 28px;
  border-radius: 4px;
  text-align: center;
  background: #409eff;
  font-size: 16px;
  color: #fff;
  font-weight: lighter;
}
.left {
  text-rendering: optimizeLegibility;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 16px;
  width: 50%;
  float: left;
}
.line {
  text-rendering: optimizeLegibility;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  margin: 20px 0;
  overflow: hidden;
  font-size: 16px;
  line-height: 16px;
}
.cont {
  text-rendering: optimizeLegibility;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  width: calc(100% - 60px);
  margin: 30px auto;
}
.wrapper {
  text-rendering: optimizeLegibility;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.memberInfo {
  text-rendering: optimizeLegibility;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
}
.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 600px;
  height: 300px;
}
.form {
  display: flex;
   flex-wrap: wrap;
  div {
    margin-right: 20px;
    span {
      font-weight: bold;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
.span {
  color: #000;
  font-size: 16px;
}
// 弹出框样式
.tanchukuang {
  padding: 10px;
  div {
    display: flex;
    div {
      width: 50%;
      margin: 10px;
      span:nth-child(1) {
        width: 100px;
        text-align: right;
      }
    }
  }
}
// 导出样式
.export {
  color: #fff;
  background-color: #409eff;
  width: 90px;
  min-width: 90px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
}
.export:hover {
  cursor: pointer;
}

.users span {
  margin-left: 30px;
}
.el-form-item {
  margin-bottom: 10px;
}
.el-form-item__label {
  color: #000 !important;
  font-weight: bold;
}
</style>
